<template>
    <div>
        <div class="mp-bg pb-5">
            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-12 text-center pt-5">
                        <h2>{{ $t('message.pre_reservation_page.title1') }}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-reservation pt-3 pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3 class="pt-4 pb-3">
                            {{ $t('message.pre_reservation_page.title2') }}
                        </h3>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="card">
                                    <h4>
                                        {{ $t('message.pack_name', { 'pack':
                                        $t(`message.pack[0].${selectedPackData.pack_name}`) }) }}
                                    </h4>
                                    <p class="thinner">
                                        {{ selectedPackData.car_name }} {{ $t('message.similar') }}
                                    </p>
                                    <p>
                                        <strong>{{ $t('message.form.pickup') }}: </strong> {{ form.pickup_location }} -
                                        {{ form.pickup_date }}
                                    </p>
                                    <p>
                                        <strong>{{ $t('message.form.delivery') }}: </strong> {{ setDeliveryLocation() }}
                                        -
                                        {{ form.delivery_date }}
                                    </p>
                                    <img class="card-img-top mt-3"
                                         :src="setImg()"
                                         alt="">
                                    <div class="divider"></div>
                                    <h5>{{ $t('message.form.value_title') }}</h5>

                                    <div class="rent-totals">
                                        <div class="subtotal">
                                            <div class="d-flex justify-content-between header">
                                                <div>{{ $t('message.form.car') }}</div>
                                                <div>{{ $t('message.form.subtotal') }}</div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>{{ rent_days }} {{ $t('message.form.day') }}(s)</div>
                                                <div>{{ rent_days * setPrice() }}€</div>
                                            </div>
                                        </div>
                                        <div class="subtotal" v-if="form.age === 'No' || tax">
                                            <div class="d-flex justify-content-between header">
                                                <div>{{ $t('message.form.taxes') }}</div>
                                                <div>{{ $t('message.form.subtotal') }}</div>
                                            </div>
                                            <div class="d-flex justify-content-between" v-if="tax">
                                                <div>{{ $t('message.form.one_way') }}</div>
                                                <div>{{ calculateOneWayTax() }}€</div>
                                            </div>
                                            <div class="d-flex justify-content-between" v-if="form.age === 'No'">
                                                <div>{{ $t('message.form.age_minor') }}</div>
                                                <div>{{ calculateAgePrice() }}€</div>
                                            </div>
                                        </div>
                                        <div class="subtotal"
                                             v-if="form.via_verde === true || form.baby_chair === true || form.border === true">
                                            <div class="d-flex justify-content-between header">
                                                <div>Extras</div>
                                                <div>{{ $t('message.form.subtotal') }}</div>
                                            </div>
                                            <div class="d-flex justify-content-between" v-if="form.via_verde === true">
                                                <div>
                                                    1 x {{ $t('message.form.viaverde') }} {{ $t('message.form.to') }}
                                                    {{ rent_days }} {{ $t('message.form.day') }}(s)
                                                </div>
                                                <div>{{ calculateViaVerde() }}€</div>
                                            </div>
                                            <div class="d-flex justify-content-between" v-if="form.baby_chair === true">
                                                <div>
                                                    1 x {{ $t('message.form.baby_chair') }} {{ $t('message.form.to') }}
                                                    {{ rent_days }} {{ $t('message.form.day') }}(s)
                                                </div>
                                                <div>{{ calculateBabyChair() }}€</div>
                                            </div>
                                            <div class="d-flex justify-content-between" v-if="form.border === true">
                                                <div>
                                                    1 x {{ $t('message.form.border') }} {{ $t('message.form.to') }}
                                                    {{ rent_days }} {{ $t('message.form.day') }}(s)
                                                </div>
                                                <div>{{ calculateBorderPrice() }}€</div>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-between total">
                                            <div>{{ $t('message.form.total_preview') }}</div>
                                            <div>* {{ total }}€</div>
                                        </div>

                                        <div class="tax-msg">
                                            * {{ $t('message.tax') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-8">
                                <div class="card">
                                    <h4>{{ $t('message.form.receipt') }}</h4>

                                    <div class="alert alert-danger mt-3" role="alert"
                                         v-if="$v.form.name.$error && errors.length > 0">
                                        <div v-for="error in errors">
                                            {{ error }}
                                        </div>
                                    </div>

                                    <div class="alert alert-danger mt-3" role="alert"
                                         v-if="$v.form.name.$error && errors.length < 1">
                                        {{ $t('message.form.invalid_form') }}
                                    </div>

                                    <form action="" class="mt-3" @submit.prevent="submit">
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="" :class="{ 'hasError': $v.form.name.$error }">{{
                                                        $t('message.form.name') }}
                                                        <span style="color:red">*</span></label>
                                                    <input class="form-control" type="text" name="name"
                                                           :class="{ 'hasError': $v.form.name.$error }"
                                                           v-model="form.name">
                                                    <div class="error" v-if="!$v.form.name.required">
                                                        {{ $t('message.form.required_field') }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="">{{ $t('message.form.company_name') }}</label>
                                                    <input class="form-control" type="text" name="company_name"
                                                           v-model="form.company_name">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="" :class="{ 'hasError': $v.form.email.$error }">{{
                                                        $t('message.form.email') }}<span
                                                                style="color:red">*</span></label>
                                                    <input class="form-control" type="text" name="email"
                                                           v-model="form.email"
                                                           :class="{ 'hasError': $v.form.email.$error }">
                                                    <div class="error" v-if="!$v.form.email.required">
                                                        {{ $t('message.form.required_field') }}
                                                    </div>
                                                    <div class="error" v-if="!$v.form.phone.email">
                                                        {{ $t('message.form.email_format') }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="" :class="{ 'hasError': $v.form.phone.$error }">{{
                                                        $t('message.form.phone') }}
                                                        <span style="color:red">*</span></label>
                                                    <input class="form-control" type="text" name="phone"
                                                           v-model="form.phone"
                                                           :class="{ 'hasError': $v.form.phone.$error }">
                                                    <div class="error" v-if="!$v.form.phone.required">
                                                        {{ $t('message.form.required_field') }}
                                                    </div>
                                                    <div class="error" v-if="!$v.form.phone.phone">
                                                        {{ $t('message.form.phone_format') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="" :class="{ 'hasError': $v.form.age.$error }">{{
                                                    $t('message.form.age') }}
                                                    <span
                                                            style="color:red">*</span></label>
                                                <p class="pb-2">
                                                    {{ $t('message.form.age_question') }}
                                                </p>
                                                <div class="form-check form-check-inline age">
                                                    <input class="form-check-input" type="radio"
                                                           name="age" id="inlineRadio1" value="Yes" v-model="form.age">
                                                    <label class="form-check-label" for="inlineRadio1">{{
                                                        $t('message.yes') }}</label>
                                                </div>
                                                <div class="form-check form-check-inline age">
                                                    <input class="form-check-input" type="radio"
                                                           name="age" id="inlineRadio2" value="No" v-model="form.age">
                                                    <label class="form-check-label" for="inlineRadio2">{{
                                                        $t('message.no') }}</label>
                                                </div>
                                                <div class="error" v-if="!$v.form.age.required">
                                                    {{ $t('message.form.required') }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="divider"></div>

                                        <h5 class="mt-4">
                                            Extras:
                                        </h5>

                                        <div class="extras pt-2">
                                            <div class="d-flex justify-content-between pb-2">
                                                <div>
                                                    <h5 v-b-toggle.collapse-1>
                                                        {{ $t('message.form.viaverde') }} <i
                                                            class="pl-2 fas fa-chevron-down"></i>
                                                    </h5>
                                                    <b-collapse id="collapse-1">
                                                        <b-card>{{ $t('message.form.viaverde_content') }}</b-card>
                                                    </b-collapse>
                                                </div>
                                                <div>
                                                    <b-button :pressed.sync="form.via_verde" variant="primary"
                                                              v-model="form.via_verde">
                                                        {{ form.via_verde === true ?
                                                        $t('message.form.remove'): $t('message.form.add') }}
                                                    </b-button>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between pb-2">
                                                <div>
                                                    <h5 v-b-toggle.collapse-2>
                                                        {{ $t('message.form.baby_chair') }} <i
                                                            class="pl-2 fas fa-chevron-down"></i>
                                                    </h5>
                                                    <b-collapse id="collapse-2">
                                                        <b-card>{{ $t('message.form.baby_chair_content') }}</b-card>
                                                    </b-collapse>
                                                </div>
                                                <div>
                                                    <b-button :pressed.sync="form.baby_chair" variant="primary"
                                                              v-model="form.baby_chair">
                                                        {{ form.baby_chair === true ?
                                                        $t('message.form.remove'): $t('message.form.add') }}
                                                    </b-button>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <h5 v-b-toggle.collapse-3>
                                                        {{ $t('message.form.border') }}<i
                                                            class="pl-2 fas fa-chevron-down"></i>
                                                    </h5>
                                                    <b-collapse id="collapse-3">
                                                        <b-card>{{ $t('message.form.border_content') }}</b-card>
                                                    </b-collapse>
                                                </div>
                                                <div>
                                                    <b-button :pressed.sync="form.border" variant="primary"
                                                              v-model="form.border">
                                                        {{ form.border === true ?
                                                        $t('message.form.remove'): $t('message.form.add') }}
                                                    </b-button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="divider"></div>

                                        <h5 class="mt-4">
                                            {{ $t('message.form.payment') }}
                                        </h5>

                                        <div class="payment pt-2 pb-3">
                                            <div class="boxed">
                                                <p class="title">
                                                    {{ $t('message.form.payment_act') }}
                                                </p>
                                                <p class="body">
                                                    {{ $t('message.form.payment_info_1') }}
                                                </p>
                                            </div>

                                            <div class="form-check pt-3 pb-2">
                                                <input class="form-check-input" type="checkbox" value="true"
                                                       name="rgpd"
                                                       id="defaultCheck2" v-model="form.rgpd">
                                                <label class="form-check-label" for="defaultCheck2"
                                                       :class="{ 'hasError': $v.form.rgpd.$error }">
                                                    {{ $t('message.form.privacy') }}
                                                    <a style="text-decoration: underline"
                                                       :href="`/${lang}/politica-privacidade`" target="_blank">{{
                                                        $t('message.form.privacy_link') }}</a> <span
                                                        style="color:red;"> *</span>
                                                </label>
                                            </div>

                                            <div class="form-check pt-3 pb-3">
                                                <input class="form-check-input" type="checkbox" value="true"
                                                       name="terms"
                                                       id="defaultCheck1" v-model="form.terms">
                                                <label class="form-check-label" for="defaultCheck1"
                                                       :class="{ 'hasError': $v.form.terms.$error }">
                                                    {{ $t('message.form.rgpd') }} <a style="text-decoration:underline;"
                                                                                     :href="`/${lang}/condicoes-gerais`"
                                                                                     target="_blank">{{
                                                    $t('message.form.terms') }}</a> <span v-if="lang === 'pt'">{{ $t('message.form.rgpd2') }} </span><span
                                                        style="color:red;"> *</span>
                                                </label>
                                            </div>
                                        </div>

                                        <button class="btn btn-primary btn-block">
                                            {{ $t('message.form.pre_reservation') }}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mp-location-bg pt-5 pb-5">
            <Location></Location>
        </div>
    </div>
</template>


<script>

    import axios                      from 'axios';
    import Swal                       from 'sweetalert2';
    import {email, helpers, required} from 'vuelidate/lib/validators';
    import json                       from '../../website-data.json';
    import Location                   from '../components/Location';

    export default {
        name         : 'ReservationPage',
        components   : {
            Location
        },
        data         : function () {
            let query = this.$route.query;
            return {
                json             : json,
                lang             : this.$route.params.lang,
                tax              : false,
                form             : {
                    delivery_location : query.delivery_location !== '' ? query.delivery_location : query.pickup_location,
                    delivery_change   : query.delivery_change !== 'false',
                    pickup_location   : query.pickup_location,
                    pickup_date       : query.pickup_date + ' ' + query.pickup_time,
                    delivery_date     : query.delivery_date + ' ' + query.delivery_time,
                    pack              : query.pack,
                    name              : '',
                    company_name      : '',
                    phone             : '',
                    email             : '',
                    age               : '',
                    via_verde         : false,
                    baby_chair        : false,
                    border            : false,
                    terms             : false,
                    rgpd              : false,
                },
                selectedPackData : this.getPackData(query.pack),
                rent_days        : this.getNumberOfDays(query.pickup_date, query.delivery_date, query.pickup_time, query.delivery_time),
                total            : 0,
                errors           : [],
                success          : false,
                loadSpinner      : false
            };
        },
        computed     : {
            rules() {

                const mustBeTrue = (value) => !helpers.req(value) || value === true;
                const phone      = helpers.regex('phone', /^[0-9]\d{8}$/);
                return {
                    name              : { required },
                    phone             : { required, phone },
                    email             : { required, email },
                    age               : { required },
                    pickup_date       : { required },
                    delivery_date     : { required },
                    pickup_location   : { required },
                    terms             : { required, mustBeTrue },
                    rgpd              : { required, mustBeTrue },
                    delivery_location : this.form.delivery_change === true ? { required } : {},
                };
            },
            path() {
                return this.$store.getters.setLocale;
            },
        },
        validations() {
            return {
                form : this.rules
            };
        },
        watch        : {
            // call the method again if the route changes
            '$route' : 'asyncData'
        },
        created      : function () {
            this.$watch('form', this.calculateTotal, {
                deep : true
            });
        },
        mounted      : function () {
            this.calculateTotal();
        },
        beforeCreate : function () {
            if (new Date(this.$route.query.pickup_date) > new Date(this.$route.query.delivery_date)) {
                Swal.fire({
                    text              : this.$route.params.lang === 'pt' ? 'A data de entrega não pode ser inferior à data de recolha.' : 'Delivery date cannot be lower than pickup date.',
                    type              : 'error',
                    confirmButtonText : '',
                    showConfirmButton : false,
                    allowOutsideClick : false,
                    timer             : 5000,
                    icon              : 'error'
                });
                setTimeout(() => {
                    this.$router.push({ path : `/${this.$route.params.lang}` }).catch(err => {
                    });
                }, 5000);
            }
        },
        methods      : {
            setPrice() {
                if (this.rent_days < 3 && this.selectedPackData.pack_route === 'grupo-b') {
                    return this.selectedPackData.short_term_price;
                }

                return this.selectedPackData.price;
            },
            setDeliveryLocation() {
                return this.form.delivery_location === '' ? this.form.pickup_location : this.form.delivery_location;
            },
            submit(e) {
                e.preventDefault();
                this.$v.form.$touch();
                if (this.$v.form.$error) {
                    this.scrollToTop();
                    return;
                }

                this.form.pack_name        = this.selectedPackData.pack_name;
                this.form.rent_days        = this.rent_days;
                this.form.total_price      = this.total;
                this.form.viaverde_price   = this.calculateViaVerde();
                this.form.age_price        = this.calculateAgePrice();
                this.form.baby_chair_price = this.calculateBabyChair();
                this.form.border_price     = this.calculateBorderPrice();
                this.form.one_way_tax      = this.calculateOneWayTax();
                this.form.lang             = this.lang;

                let vm = this;

                axios.post(process.env.VUE_APP_RESERVATION_URL, vm.form, {
                        headers : {
                            'Content-Type'                : 'application/json',
                            'Access-Control-Allow-Origin' : '*',
                            'Accept'                      : 'application/json',
                        }
                    })
                    .then(function (response) {
                        if (response.data.message === 'pre-reservation accepted') {
                            vm.success     = true;
                            vm.loadSpinner = false;
                            vm.dispatchSubmitEvent();

                            let text = vm.lang === 'pt' ? 'Pedido enviado com sucesso! Irá receber em breve confirmação no seu email.' : 'Pre-reservation submitted. A confirmation email was sent.';
                            Swal.fire({
                                text              : text,
                                type              : 'success',
                                confirmButtonText : '',
                                showConfirmButton : false,
                                allowOutsideClick : false,
                                timer             : 5000,
                                icon              : 'success'
                            });

                            setTimeout(() => {
                                vm.$router.push({ path : `/${vm.lang}` }).catch(err => {
                                });
                            }, 5000);
                        } else {
                            vm.errors      = response.data.email;
                            vm.loadSpinner = false;
                            vm.disabled    = false;
                        }
                    })
                    .catch(function (error) {
                        vm.errors      = ['Error. Something happened.'];
                        vm.loadSpinner = false;
                        vm.disabled    = false;
                    });
            },
            dispatchSubmitEvent() {
                this.$gtm.trackEvent({
                    event          : 'K2GO-PedidoSucesso', // Event type [default = 'interaction'] (Optional)
                    category       : 'K2GO-PedidoSucesso',
                    action         : 'K2GO-PedidoSucesso',
                    label          : 'K2GO-PedidoSucesso',
                    value          : 0,
                    noninteraction : false // Optional
                });
            },
            calculateTotal() {
                let carTotal = this.setPrice() * this.rent_days;
                this.total   = carTotal + this.calculateBabyChair() + this.calculateAgePrice() + this.calculateViaVerde() + this.calculateBorderPrice() + this.calculateOneWayTax();
            },
            calculateViaVerde() {
                let viaVerdePrice = 1.5;// 1,5€ dia, max 15€
                let maxPrice      = 15;
                if (this.form !== undefined && this.form.via_verde) {
                    let total = viaVerdePrice * this.rent_days;
                    return total >= maxPrice ? maxPrice : total;
                } else {
                    return 0;
                }
            },
            calculateBabyChair() {
                let babyChairPrice = 9; // 9€ dia, max 35€
                let maxPrice       = 35;
                if (this.form !== undefined && this.form.baby_chair) {
                    let total = babyChairPrice * this.rent_days;
                    return total >= maxPrice ? maxPrice : total;
                } else {
                    return 0;
                }
            },
            calculateAgePrice() {
                let agePrice = 5; // dia
                return this.form !== undefined && this.form.age === 'No' ? agePrice * this.rent_days : 0;
            },
            calculateBorderPrice() {
                let borderPrice = 75; // fixed price
                return this.form !== undefined && this.form.border ? borderPrice : 0;
            },
            calculateOneWayTax() {
                // Lisboa/Porto - Leiria/Fátima = 85€
                // Leiria/Fátima - Lisboa/Porto = 85€
                // Lisboa - Porto  = 85€ x2
                // Porto - Lisboa = 85€ x2
                // Lisboa - Lisboa = 85€ x2
                // Porto - Porto = 85€ x2
                if ((this.form.pickup_location === 'Lisboa' || this.form.pickup_location === 'Porto')
                    && (this.form.delivery_location === 'Leiria' || this.form.delivery_location === 'Fátima')) {
                    this.tax = true;
                    return 85;
                } else if ((this.form.pickup_location === 'Leiria' || this.form.pickup_location === 'Fátima')
                    && (this.form.delivery_location === 'Lisboa' || this.form.delivery_location === 'Porto')) {
                    this.tax = true;
                    return 85;
                } else if ((this.form.pickup_location === 'Porto' && this.form.delivery_location === 'Lisboa')) {
                    this.tax = true;
                    return 85 * 2;
                } else if ((this.form.pickup_location === 'Lisboa' && this.form.delivery_location === 'Porto')) {
                    this.tax = true;
                    return 85 * 2;
                } else if ((this.form.pickup_location === 'Lisboa' && this.form.delivery_location === 'Lisboa')) {
                    this.tax = true;
                    return 85 * 2;
                } else if ((this.form.pickup_location === 'Porto' && this.form.delivery_location === 'Porto')) {
                    this.tax = true;
                    return 85 * 2;
                } else if (this.form.pickup_location === 'Lisboa') {
                    this.tax = true;
                    return 85 * 2;
                } else if (this.form.pickup_location === 'Porto') {
                    this.tax = true;
                    return 85 * 2;
                } else {
                    this.tax = false;
                    return 0;
                }
            },
            getNumberOfDays(date1, date2, time1, time2) {
                let dateDiff = this.datediff(this.parseDate(date1, time1), this.parseDate(date2, time2));
                return Math.ceil(dateDiff);
            },
            getPackData(pack) {
                let val = json.packs.filter((e) => {
                    return e.pack_route === pack;
                });

                return val[0];
            },
            parseDate(str, str2) {
                let dmy = str.split('/');
                let hm  = str2.split(':');
                return new Date(dmy[2], dmy[1] - 1, dmy[0], hm[0], hm[1]);
            },
            datediff(first, second) {
                // Take the difference between the dates and divide by milliseconds per day.
                // Round to nearest whole number to deal with DST.
                return (second - first) / (1000 * 60 * 60 * 24);
            },
            setImg() {
                let screen = window.innerWidth < 768 ? 'mobile' : 'desktop';
                return `https://vault13.rotauto.com/sites/k2go/cars/${this.path}/${screen}/${this.selectedPackData.img_src}`;
            },
            scrollToTop() {
                window.scroll({
                    top      : 0,
                    left     : 0,
                    behavior : 'smooth'
                });
            },
        }
    };
</script>
